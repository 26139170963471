<template>
    <section class="theme-section">
        <div class="theme-container">
            <div class="header">
                <h2 class="title">Powerful Customization</h2>
                <p class="description">
                    Take full control of your forms with advanced settings and beautiful themes.
                    Configure notifications, automation rules, and brand your forms to match your
                    organization's identity perfectly.
                </p>
            </div>

            <div class="panels-grid">
                <!-- Settings Panel -->
                <div class="panel">
                    <div class="panel-header">
                        <!-- <span class="icon">⚙️</span> -->
                        <SettingsIcon></SettingsIcon>
                        <h3 class="panel-title">Advanced Settings</h3>
                    </div>
                    <div class="settings-list">
                        <div class="setting-item">
                            <span class="setting-label">Email Notifications</span>
                            <div class="toggle-switch">
                                <span class="toggle-button"></span>
                            </div>
                        </div>
                        <div class="setting-item">
                            <span class="setting-label">Auto-save</span>
                            <div class="toggle-switch">
                                <span class="toggle-button"></span>
                            </div>
                        </div>
                    </div>
                </div>

                <!-- Theme Panel -->
                <div class="panel">
                    <div class="panel-header">
                        <!-- <span class="icon">🎨</span> -->
                        <PaletteIcon></PaletteIcon>
                        <h3 class="panel-title">Theme Customization</h3>
                    </div>
                    <div class="color-grid">
                        <div class="color-block purple"></div>
                        <div class="color-block blue"></div>
                        <div class="color-block green"></div>
                        <div class="color-block red"></div>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
import SettingsIcon from "@/components/ui/icons/SettingsIcon.vue";
import PaletteIcon from "@/components/ui/icons/UsersIcon.vue";
export default {
    name: 'ThemeSection',
    components: {
        SettingsIcon,
        PaletteIcon,
    },
}
</script>

<style>
.theme-section {
    background-color: white;
}

.theme-container {
    max-width: 72rem;
    margin: 0 auto;
    padding: 64px 16px;
}

.header {
    text-align: center;
    margin-bottom: 48px;
}

.title {
    font-size: 30px;
    font-weight: 700;
    color: #111827;
    margin-bottom: 16px;
}

.description {
    font-size: 18px;
    color: #4B5563;
    max-width: 768px;
    margin: 0 auto;
}

.panels-grid {
    display: grid;
    gap: 48px;
}

@media (min-width: 768px) {
    .panels-grid {
        grid-template-columns: 1fr 1fr;
    }
}

.panel {
    background-color: white;
    border-radius: 12px;
    box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1);
    padding: 24px;
}

.panel-header {
    display: flex;
    align-items: center;
    gap: 16px;
    margin-bottom: 24px;
}

.icon {
    font-size: 24px;
    color: #9333EA;
}

.panel-title {
    font-size: 18px;
    font-weight: 600;
    color: #1F2937;
    margin-bottom: 0;
}

.settings-list {
    display: flex;
    flex-direction: column;
    gap: 16px;
}

.setting-item {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 12px;
    background-color: #F9FAFB;
    border-radius: 8px;
}

.setting-label {
    font-size: 14px;
    color: #374151;
}

.toggle-switch {
    display: flex;
    align-items: center;
    width: 40px;
    height: 24px;
    background-color: #d1d5db;
    border-radius: 9999px;
    cursor: pointer;
}

.toggle-button {
    background-color: #374151;
    width: 1rem;
    height: 1rem;
    display: block;
    border-radius: 50%;
    margin-left: 0.2rem;
}

.color-grid {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 16px;
}

.color-block {
    width: 100%;
    height: 80px;
    border-radius: 8px;
}

.color-block.purple {
    background-color: #9333EA;
}

.color-block.blue {
    background-color: #3B82F6;
}

.color-block.green {
    background-color: #10B981;
}

.color-block.red {
    background-color: #EF4444;
}
</style>