<template>
    <section class="preview-section">
        <div class="preview-container">
            <div class="panels-grid">
                <!-- Preview Panel -->
                <div class="panel">
                    <div class="panel-header">
                        <!-- <span class="icon">👁️</span> -->
                        <EyeIcon></EyeIcon>
                        <h3 class="panel-title">Live Preview</h3>
                    </div>
                    <div class="form-preview">
                        <div class="form-fields">
                            <div class="form-group">
                                <label class="form-label">Name</label>
                                <input type="text" class="form-input" />
                            </div>
                            <div class="form-group">
                                <label class="form-label">Email</label>
                                <input type="email" class="form-input" />
                            </div>
                        </div>
                    </div>
                </div>

                <!-- Users Panel -->
                <div class="panel">
                    <div class="panel-header">
                        <!-- <span class="icon">👥</span> -->
                        <UsersIcon></UsersIcon>
                        <h3 class="panel-title">Team Collaboration</h3>
                    </div>
                    <div class="users-list">
                        <div class="user-item">
                            <div class="user-info">
                                <div class="avatar purple"></div>
                                <span class="user-name">John Doe</span>
                            </div>
                            <span class="status-badge online">Online</span>
                        </div>
                        <div class="user-item">
                            <div class="user-info">
                                <div class="avatar blue"></div>
                                <span class="user-name">Jane Smith</span>
                            </div>
                            <span class="status-badge away">Away</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
import EyeIcon from "@/components/ui/icons/EyeIcon.vue";
import UsersIcon from "@/components/ui/icons/UsersIcon.vue";
export default {
    name: 'PreviewSection',
    components: {
        EyeIcon,
        UsersIcon,
    },
}
</script>

<style>
.preview-section {
    background-color: #F9FAFB;
}

.preview-container {
    max-width: 72rem;
    margin: 0 auto;
    padding: 64px 16px;
}

.panels-grid {
    display: grid;
    gap: 48px;
}

@media (min-width: 768px) {
    .panels-grid {
        grid-template-columns: 1fr 1fr;
    }
}

.panel {
    background-color: white;
    border-radius: 12px;
    box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1);
    padding: 24px;
}

.panel-header {
    display: flex;
    align-items: center;
    gap: 16px;
    margin-bottom: 24px;
}

.icon {
    font-size: 24px;
    color: #9333EA;
}

.panel-title {
    font-size: 18px;
    font-weight: 600;
    color: #1F2937;
}

.form-preview {
    border: 1px solid #E5E7EB;
    border-radius: 8px;
    padding: 16px;
}

.form-fields {
    display: flex;
    flex-direction: column;
    gap: 16px;
}

.form-group {
    display: flex;
    flex-direction: column;
    gap: 4px;
}

.form-label {
    font-size: 14px;
    font-weight: 500;
    color: #374151;
}

.form-input {
    width: 100%;
    padding: 8px;
    border: 1px solid #D1D5DB;
    border-radius: 8px;
}

.users-list {
    display: flex;
    flex-direction: column;
    gap: 16px;
}

.user-item {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 12px;
    background-color: #F9FAFB;
    border-radius: 8px;
}

.user-info {
    display: flex;
    align-items: center;
    gap: 12px;
}

.avatar {
    width: 32px;
    height: 32px;
    border-radius: 50%;
}

.avatar.purple {
    background-color: #E9D5FF;
}

.avatar.blue {
    background-color: #BFDBFE;
}

.user-name {
    font-size: 14px;
    color: #374151;
}

.status-badge {
    font-size: 12px;
    padding: 4px 8px;
    border-radius: 9999px;
}

.status-badge.online {
    background-color: #DCFCE7;
    color: #15803D;
}

.status-badge.away {
    background-color: #F3F4F6;
    color: #374151;
}
</style>