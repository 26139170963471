<template>
	<div id="app" class="app--theme">
		<!-- <div class="overlay" :class="{active: isOverlay}"></div> -->
		<Message></Message>
    <Overlay v-show="overlaying"></Overlay>
		<Loader v-show="loading"></Loader>
		<button type="button" class="top--arrow" id="top--arrow" v-scroll="handleScroll" @click.stop.prevent="toTop">&uarr;</button>
		<router-view/>
	</div>
</template>

<script>
// @ is an alias to /src
import Message from "@/components/partials/Message.vue";
import Overlay from "@/components/partials/Overlay.vue";
import Loader from "@/components/partials/Loader.vue";
import { mapGetters } from "vuex";
export default {
  components: {
    Message,
    Overlay,
    Loader,
  },
  computed: {
    ...mapGetters(["overlaying", "loading"]),
  },
  methods: {
    handleScroll: function (evt, el) {
      if (window && window.pageYOffset > 50) {
        el.classList.add("active");
      } else {
        el.classList.remove("active");
      }
    },
    toTop() {
      setTimeout(() => {
        window.scrollTo(0, 0);
      }, 500);
    },
    beforeMount() {
      window.addEventListener("scroll", this.handleScroll);
    },
    beforeDestroy() {
      window.removeEventListener("scroll", this.handleScroll);
    },
  },
};
</script>

<style>
*,
::after,
::before {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}
*,
::after,
::before {
  box-sizing: border-box;
  border-width: 0;
  border-style: solid;
  border-color: #e5e7eb;
  border-top-color: rgb(229, 231, 235);
  border-right-color: rgb(229, 231, 235);
  border-bottom-color: rgb(229, 231, 235);
  border-left-color: rgb(229, 231, 235);
}
html,
body {
  font-family: ui-sans-serif, system-ui, -apple-system, BlinkMacSystemFont,
    "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif,
    "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  line-height: 1.5;
  font-size: 1rem !important;
  color: #000;
}
/* h1,
h2,
h3,
h4,
h5,
h6 {
  font-size: inherit;
} */
audio,
canvas,
embed,
iframe,
img,
object,
svg,
video {
  display: block;
  /* vertical-align: middle; */
}
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  /*color: #2c3e50;*/
  /*background-color: rgba(209,213,219, 1);*/
}
a {
  /*color: inherit;*/
  text-decoration: inherit;
  text-decoration-line: inherit;
  text-decoration-thickness: inherit;
  text-decoration-style: inherit;
  text-decoration-color: inherit;
}
.app--theme {
  /*background-color: #fff;*/
  /*color: #222;*/
  background-color: rgba(243, 244, 246, 1);
  color: rgba(55, 65, 81, 1);
}
.app--theme.active {
  /*background-color: #222;*/
  /*color: #fff;*/
  background-color: rgba(17, 24, 39, 1);
  color: rgba(156, 163, 175, 1);
}
.white--themes {
  background-color: #fff;
  color: #222;
}
.dark--themes {
  background-color: #222;
  color: #fff;
}

/* up arrow */
.top--arrow {
  position: absolute;
  display: none;
  font-size: 2rem;
  font-weight: 500;
  text-align: center;
  width: 3rem;
  height: 3rem;
  margin: 0.8rem;
  outline: none;
  bottom: 0;
  background-color: #707474;
  border: 0.1rem solid #616565;
  border-radius: 50%;
  content: "";
  transition: all 1s ease-out;
  z-index: 10;
}
.top--arrow.active {
  position: fixed;
  display: block;
  right: 0;
}
.top--arrow:hover {
  background-color: #616565;
  border: 0.1rem solid #707474;
  transition: all 0.6s ease-in;
}
.scroll-top-button {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 2.5rem;
  height: 2.5rem;
  background-color: #d1d5db;
  color: white;
  border-radius: 9999px;
  border: none;
  box-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1);
  position: fixed;
  bottom: 2rem;
  right: 2rem;
  cursor: pointer;
}

.scroll-top-button:hover {
  background-color: #6d28d9;
}

.scroll-icon {
  width: 1.5rem;
  height: 1.5rem;
  stroke: currentColor;
  stroke-width: 2;
  stroke-linecap: round;
  stroke-linejoin: round;
  fill: none;
}
/* max screen */
@media screen and (min-width: 981px) {
}
</style>
