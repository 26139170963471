<template>
    <section class="form-section">
        <div class="form-container">
            <div class="grid">
                <div class="dashboard-card">
                    <div class="dashboard-header">
                        <!-- <span class="folder-icon">📁</span> -->
                        <FolderIcon></FolderIcon>
                        <h3 class="dashboard-title">Models Dashboard</h3>
                    </div>
                    <div class="form-list">
                        <div v-for="(form, index) in forms" :key="index" class="form-item">
                            <span class="form-name">{{ form }}</span>
                            <span class="form-timestamp">Last edited 2h ago</span>
                        </div>
                    </div>
                </div>
                <div class="content-left">
                    <h1 class="heading">Your Vision, Our Templates</h1>
                    <p class="subheading">
                        Explore a diverse library of form templates, from simple surveys to complex applications. 
                        Tailor each one to perfectly match your needs..
                    </p>
                    <!-- <div class="button-group">
                        <button class="primary-button">
                            Get Started Free
                            <span class="icon">→</span>
                        </button>
                        <button class="secondary-button">View Demo</button>
                    </div> -->
                </div>
            </div>
        </div>
    </section>
</template>

<script>
import FolderIcon from "@/components/ui/icons/FolderIcon.vue";
export default {
    name: 'FormSection',
    components: {
        FolderIcon,
    },
    data() {
        return {
            forms: [
                'Test DB Form',
                'Test Form 2',
                'Test Form 3',
                'Test Form 1',
                'Test Form 4'
            ]
        }
    }
}
</script>

<style>
.form-section {
    background-color: #fff;
}

.form-container {
    max-width: 72rem;
    margin: 0 auto;
    padding: 64px 16px;
}

.grid {
    display: grid;
    gap: 48px;
    align-items: center;
}

@media (min-width: 768px) {
    .grid {
        grid-template-columns: 1fr 1fr;
    }
}

.content-left {
    text-align: left;
}

.heading {
    font-size: 36px;
    font-weight: 700;
    color: #111827;
    margin-bottom: 24px;
}

@media (min-width: 768px) {
    .heading {
        font-size: 48px;
    }
}

.subheading {
    font-size: 20px;
    color: #4B5563;
    margin-bottom: 32px;
}

.button-group {
    display: flex;
    gap: 16px;
}

.primary-button {
    background-color: #9333EA;
    color: white;
    padding: 12px 24px;
    border-radius: 8px;
    display: flex;
    align-items: center;
    border: none;
    cursor: pointer;
}

.primary-button:hover {
    background-color: #7E22CE;
}

.icon {
    margin-left: 8px;
}

.secondary-button {
    border: 1px solid #D1D5DB;
    color: #374151;
    padding: 12px 24px;
    border-radius: 8px;
    background-color: transparent;
    cursor: pointer;
}

.secondary-button:hover {
    background-color: #F9FAFB;
}

.dashboard-card {
    background-color: white;
    border-radius: 12px;
    box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1);
    border: 1px solid #F3F4F6;
    padding: 24px;
}

.dashboard-header {
    display: flex;
    align-items: center;
    gap: 16px;
    margin-bottom: 24px;
}

.folder-icon {
    color: #9333EA;
    font-size: 24px;
}

.dashboard-title {
    font-size: 18px;
    font-weight: 600;
    color: #1F2937;
}

.form-list {
    display: flex;
    flex-direction: column;
    gap: 12px;
}

.form-item {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 12px;
    background-color: #F9FAFB;
    border-radius: 8px;
    border: 1px solid #E5E7EB;
}

.form-name {
    font-size: 14px;
    color: #374151;
}

.form-timestamp {
    font-size: 12px;
    color: #6B7280;
}
</style>