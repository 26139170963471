<template>
    <section class="insights-section">
        <div class="insights-container">
            <div class="grid">
                <div class="content">
                    <h2 class="heading">Powerful Insights</h2>
                    <p class="description">
                        Track form performance, user engagement, and submission patterns with
                        detailed analytics and visual reports.
                    </p>
                </div>
                <div class="analytics-card">
                    <div class="card-header">
                        <!-- <span class="chart-icon">📊</span> -->
                         <BarChartIcon></BarChartIcon>
                        <h3 class="card-title">Real-time Analytics</h3>
                    </div>
                    <div class="chart-container">
                        <div class="chart-bars">
                            <div class="bar bar-1"></div>
                            <div class="bar bar-2"></div>
                            <div class="bar bar-3"></div>
                            <div class="bar bar-4"></div>
                            <div class="bar bar-5"></div>
                            <div class="bar bar-6"></div>
                            <div class="bar bar-7"></div>
                            <!-- <div class="bar bar-8"></div>
                            <div class="bar bar-9"></div>
                            <div class="bar bar-10"></div> -->
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
import BarChartIcon from "@/components/ui/icons/BarChartIcon.vue";
export default {
    name: 'InsightsSection',
    components: {
        BarChartIcon,
    },
}
</script>

<style>
.insights-section {
    background-color: #F9FAFB;
}

.insights-container {
    max-width: 72rem;
    margin: 0 auto;
    padding: 64px 16px;
}

.grid {
    display: grid;
    gap: 48px;
    align-items: center;
}

@media (min-width: 768px) {
    .grid {
        grid-template-columns: 1fr 1fr;
    }
}

.analytics-card {
    background-color: white;
    border-radius: 12px;
    box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1);
    padding: 24px;
}

.card-header {
    display: flex;
    align-items: center;
    gap: 16px;
    margin-bottom: 24px;
}

.chart-icon {
    font-size: 24px;
    color: #9333EA;
}

.card-title {
    font-size: 18px;
    font-weight: 600;
    color: #1F2937;
}

.chart-container {
    height: 256px;
    background-color: #F9FAFB;
    border: 1px solid #E5E7EB;
    border-radius: 8px;
    padding: 16px;
}

.chart-bars {
    display: flex;
    flex-direction: column;
    gap: 16px;
}

.bar {
    height: 16px;
    border-radius: 9999px;
}

.bar-1 {
    width: 95%;
    background-color: #D1D5DB;
}

.bar-2 {
    width: 90%;
    background-color: #9CA3AF;
}

.bar-3 {
    width: 85%;
    background-color: #6B7280;
}

.bar-4 {
    width: 75%;
    background-color: #4B5563;
}

.bar-5 {
    width: 65%;
    background-color: #424A59;
}

.bar-6 {
    width: 55%;
    background-color: #3D4452;
}

.bar-7 {
    width: 45%;
    background-color: #374151;  /* Darkest gray */
}

.content {
    text-align: left;
}

.heading {
    font-size: 30px;
    font-weight: 700;
    color: #111827;
    margin-bottom: 16px;
}

.description {
    font-size: 18px;
    color: #4B5563;
    margin-bottom: 24px;
}
</style>