<template>
  <div class="main--wrapper">
    <div class="main--help">
			<span>Need support? Call: <a href="`tel:${+234 803 213 0560}`" class="auth--link">&nbsp;+234 803 213 0560</a></span>
		</div>
		<div class="main--or"><span>or</span></div>
		<div class="social--media">
			<span>Connect with us</span>
			<!--<router-link class="auth--link" to="/register">&nbsp;</router-link>-->
			<ul class="social--media--list--wrapper" data-social-list="">
				<li class="social--media--list--item" data-overview-item="">
					<a href="https://twitter.com/barestripe" class="social--link" target="_blank" title="Follow us on Twitter" data-social-link="">
						<svg xmlns="http://www.w3.org/2000/svg" class="social--icon" viewBox="0 0 20 20">
						<path class="twitter" d="M16.29 6.25a5.63 5.63 0 01-1.51.41 2.56 2.56 0 001.15-1.45 5.4 5.4 0 01-1.66.64 2.62 2.62 0 00-4.54 1.79 2.35 2.35 0 00.06.6 7.43 7.43 0 01-5.41-2.75A2.65 2.65 0 004 6.82 2.61 2.61 0 005.19 9 2.64 2.64 0 014 8.68a2.65 2.65 0 002.1 2.58 3 3 0 01-.69.08 2.7 2.7 0 01-.49 0 2.65 2.65 0 002.45 1.83 5.32 5.32 0 01-3.25 1.11 4.29 4.29 0 01-.63 0A7.46 7.46 0 0015 8v-.34a5.33 5.33 0 001.29-1.41z"></path>
						</svg>
					</a>
				</li>
				<li class="social--media--list--item" data-overview-item="">
					<a href="https://www.facebook.com/barestripe" class="social--link" target="_blank" title="Follow us on Facebook" data-social-link="">
						<svg xmlns="http://www.w3.org/2000/svg" class="social--icon" viewBox="0 0 20 20">
							<path class="facebook" d="M12.77 10.7l.33-2.16H11V7.15A1.08 1.08 0 0112.25 6h.94V4.15A11.09 11.09 0 0011.52 4 2.63 2.63 0 008.7 6.9v1.64H6.81v2.16H8.7v5.21a7.59 7.59 0 002.33 0V10.7z"></path>
						</svg>
					</a>
				</li>
				<li class="social--media--list--item" data-overview-item="">
					<a href="https://www.linkedin.com/company/barestripe" class="social--link" target="_blank" title="Connect with us on LinkedIn" data-social-link="">
						<svg xmlns="http://www.w3.org/2000/svg" class="social--icon" viewBox="0 0 20 20">
						<path class="linkedin" d="M15 10.07v3.7h-2.14v-3.44c0-.86-.3-1.47-1.09-1.47a1.2 1.2 0 00-1.1.79 2.31 2.31 0 00-.07.51v3.61H8.47V7.33h2.13v.9a2.16 2.16 0 011.93-1.07C14 7.19 15 8.09 15 10.07zM6.21 4.23A1.13 1.13 0 005 5.35a1.1 1.1 0 00.35.81 1.14 1.14 0 00.84.31 1.12 1.12 0 001.23-1.12 1.13 1.13 0 00-1.21-1.12zm-1.09 9.54h2.14V7.33H5.12z"></path>
						</svg>
					</a>
				</li>
				<li class="social--media--list--item" data-overview-item="">
					<a href=" https://www.youtube.com/user/barestripe" class="social--link" target="_blank" title="Subscribe to our YouTube Channel" data-social-link="">
						<svg xmlns="http://www.w3.org/2000/svg" class="social--icon" viewBox="0 0 20 20">
							<path class="youtube" d="M10.17 5.07a37.22 37.22 0 015.3.29 1.76 1.76 0 011.24 1.25A18.6 18.6 0 0117 10a18.6 18.6 0 01-.29 3.39 1.76 1.76 0 01-1.24 1.25 37.22 37.22 0 01-5.3.29h-.34a37.22 37.22 0 01-5.3-.29 1.76 1.76 0 01-1.24-1.25A17.23 17.23 0 013 10.11v-.22a17.23 17.23 0 01.29-3.28 1.76 1.76 0 011.24-1.25 37.22 37.22 0 015.3-.29zm-1.6 2.85v4.16L12.23 10z"></path>
						</svg>
					</a>
				</li>
				<li class="social--media--list--item" data-overview-item="">
					<a href="https://www.instagram.com/barestripe" class="social--link" target="_blank" title="Follow us on instagram" data-social-link="">
						<svg xmlns="http://www.w3.org/2000/svg" class="social--icon" viewBox="0 0 20 20">
						<path class="instagram" d="M10 4H7.53a4.39 4.39 0 00-1.46.28A2.81 2.81 0 005 5a2.81 2.81 0 00-.69 1.06A4.39 4.39 0 004 7.53v4.94a4.39 4.39 0 00.28 1.46A2.81 2.81 0 005 15a3 3 0 001.06.7 4.69 4.69 0 001.47.3h4.94a4.69 4.69 0 001.46-.27 3.17 3.17 0 001.76-1.76 4.69 4.69 0 00.31-1.5V10 7.53a4.69 4.69 0 00-.27-1.46A3 3 0 0015 5a2.81 2.81 0 00-1.06-.69A4.39 4.39 0 0012.47 4H10zm2.42 1.12a3.26 3.26 0 011.12.2 2 2 0 011.14 1.14 3.26 3.26 0 01.2 1.12v4.88a3.26 3.26 0 01-.2 1.12 2 2 0 01-1.14 1.14 3.26 3.26 0 01-1.12.2H7.54a3.26 3.26 0 01-1.12-.2 2 2 0 01-1.14-1.14 3.26 3.26 0 01-.2-1.12V9.37 7.58a3.26 3.26 0 01.2-1.12 2 2 0 011.18-1.14 3.26 3.26 0 011.12-.2h3.05c1.04-.04 1.26-.03 1.79 0zM10 6.92A3.08 3.08 0 1013.08 10 3.08 3.08 0 0010 6.92zM10 8a2 2 0 11-2 2 2 2 0 012-2zm3.2-1.92a.72.72 0 10.72.72.72.72 0 00-.72-.72z"></path>
						</svg>
					</a>
				</li>
				<li class="social--media--list--item" data-overview-item="">
					<a href="https://www.twitch.tv/barestripe" class="social--link" target="_blank" title="Watch us on twitch" data-social-link="">
					<svg xmlns="http://www.w3.org/2000/svg" class="social--icon" viewBox="0 0 20 20">
						<path class="twitch" d="M5.83 4.7L5 6.87v8.83h3v1.6h1.68l1.6-1.6h2.45L17 12.41V4.7zm10.06 7.15L14 13.73h-3l-1.6 1.59v-1.59H6.87v-7.9h9zM14 8v3.29h-1.12V8zm-3 0v3.29H9.87V8z"></path>
					</svg>
					</a>
				</li>
			</ul>
		</div>
  </div>
</template>

<script>
// @ is an alias to /src
export default {
  name: "Main",
  computed: {},
  methods: {},
};
</script>

<style scoped>
/* main */
.main--wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  padding: 0.5rem;
}

.main--help {
  margin: 15px;
  color: rgb(33, 49, 60);
  font-size: 15px;
  display: inline;
  text-align: start;
  display: block;
  text-align: center;
}
.main--or {
  margin: 20px 0px;
  position: relative;
  text-align: center;
  width: 298px;
  width: calc(100vw - 32px);
  width: 100%;
}
.main--or::before {
  display: inline-block;
  border-top: 1px solid rgb(184, 196, 194);
  content: "";
  margin: 0px auto;
  position: absolute;
  height: 0px;
  inset: 50% 0px 0px;
  transform: translateY(-50%);
  width: 300px;
}
.main--or span {
  background: rgb(255, 255, 255);
  background-color: rgba(243, 244, 246, 1);
  padding: 0px 10px;
  font-size: 14px;
  font-weight: normal;
  line-height: 16px;
  color: rgb(93, 108, 116);
  text-align: center;
  position: inherit;
}
/* social--media */
.social--media--list--wrapper {
  color: rgb(255, 255, 255);
  display: flex;
  flex-direction: row;
  font-family: Ember, sans-serif;
  font-size: 10px;
  justify-content: space-between;
  line-height: 11.5px;
  list-style: none outside none;
  margin: 0;
  padding: 20px 0;
  text-rendering: optimizelegibility;
  visibility: visible;
  -webkit-box-direction: normal;
  -webkit-box-orient: horizontal;
  -webkit-box-pack: justify;
  -webkit-font-smoothing: antialiased;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}
.social--media .social--media--list--wrapper {
  display: flex;
  flex-direction: row;
  justify-content: center;
  flex-wrap: wrap;
  line-height: 11.5px;
  list-style: none outside none;
  margin: 0;
  visibility: visible;
  padding: 24px 0;
}
/* social--media--list */
.social--media--list--item {
  color: rgb(255, 255, 255);
  display: block;
  flex-shrink: 0;
  font-family: Ember, sans-serif;
  font-size: 10px;
  height: 40px;
  line-height: 11.5px;
  list-style: none outside none;
  text-align: left;
  text-rendering: optimizelegibility;
  visibility: visible;
  width: 40px;
  -webkit-box-direction: normal;
  -webkit-font-smoothing: antialiased;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}
.social--media .social--media--list--item {
  display: block;
  flex-shrink: 0;
  height: 40px;
  list-style: none outside none;
  text-align: left;
  visibility: visible;
  width: 40px;
}
.social--media .social--media--list--wrapper .social--media--list--item {
  margin: 8px;
}
/* social--media--link */
.social--link {
  background-color: rgba(229, 231, 235, 1);
  border-radius: 50%;
  box-sizing: border-box;
  color: rgb(255, 255, 255);
  cursor: pointer;
  display: block;
  font-family: Ember, sans-serif;
  font-size: 10px;
  height: 40px;
  line-height: 10px;
  list-style: none outside none;
  padding: 10px;
  position: relative;
  text-align: left;
  text-decoration-color: rgb(255, 255, 255);
  text-decoration-line: none;
  text-decoration-style: solid;
  text-rendering: optimizelegibility;
  width: 40px;
  -webkit-box-direction: normal;
  -webkit-font-smoothing: antialiased;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}
.social--link:hover {
  box-shadow: 0 1px 2px 0 rgb(60 64 67 / 30%), 0 1px 3px 1px rgb(60 64 67 / 15%);
}
.social--media a {
  font-size: 14px;
  color: #006efa;
}
.social--media a,
.social--media a:active,
.social--media a:hover,
.social--media a:visited,
.social--media a:disabled {
  text-decoration: none;
  font-weight: bold;
}
.social--media .social--link {
  background-color: #d1d5db;
  border-radius: 50%;
  box-sizing: border-box;
  display: block;
  height: 40px;
  line-height: 10px;
  list-style: none outside none;
  padding: 10px;
  position: relative;
  width: 40px;
}
/* svg */
.social--icon {
  box-sizing: border-box;
  color: #fff;
  cursor: pointer;
  display: block;
  fill: rgba(55, 65, 81, 1);
  font-family: Ember, sans-serif;
  font-size: 10px;
  height: 20px;
  line-height: 10px;
  list-style: none outside none;
  overflow-x: hidden;
  overflow-y: hidden;
  position: relative;
  text-align: left;
  text-rendering: optimizelegibility;
  width: 20px;
  -webkit-box-direction: normal;
  -webkit-font-smoothing: antialiased;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}
.social--media .social--icon {
  box-sizing: border-box;
  cursor: pointer;
  display: block;
  height: 20px;
  list-style: none outside none;
  position: relative;
  width: 20px;
}
.auth--link {
  text-decoration: none;
  color: rgb(0, 124, 173);
}
</style>