<template>
	<div class="header--wrapper">
		<header class="header">
      <div class="header--item"></div>
      <div class="header--item">
        <nav class="nav">
          <div class="nav-container">
            <router-link class="nav-logo nav--link" to="/">
              <div class="logo-icon">
                <svg class="logo--icon_" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="#000">
                  <path d="M11 17a1 1 0 001.447.894l4-2A1 1 0 0017 15V9.236a1 1 0 00-1.447-.894l-4 2a1 1 0 00-.553.894V17zM15.211 6.276a1 1 0 000-1.788l-4.764-2.382a1 1 0 00-.894 0L4.789 4.488a1 1 0 000 1.788l4.764 2.382a1 1 0 00.894 0l4.764-2.382zM4.447 8.342A1 1 0 003 9.236V15a1 1 0 00.553.894l4 2A1 1 0 009 17v-5.764a1 1 0 00-.553-.894l-4-2z"></path>
                </svg>
              </div>
              <span class="logo-text">Algoforms</span>
            </router-link>
            <div class="nav-links">
              <a href="/documentation/account" class="nav-link">Documentation</a>
              <a href="/login" class="sign-in-button">Sign In</a>
              <!-- <button class="sign-in-button">Sign In</button> -->
            </div>
          </div>
        </nav>
        <!-- <nav class="nav" id="nav">
          <ul class="nav--list">
            <li class="nav--item logo">
              <router-link class="nav--link" to="/">
                <svg class="logo--icon" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
                  <path d="M11 17a1 1 0 001.447.894l4-2A1 1 0 0017 15V9.236a1 1 0 00-1.447-.894l-4 2a1 1 0 00-.553.894V17zM15.211 6.276a1 1 0 000-1.788l-4.764-2.382a1 1 0 00-.894 0L4.789 4.488a1 1 0 000 1.788l4.764 2.382a1 1 0 00.894 0l4.764-2.382zM4.447 8.342A1 1 0 003 9.236V15a1 1 0 00.553.894l4 2A1 1 0 009 17v-5.764a1 1 0 00-.553-.894l-4-2z"></path>
                </svg>
              </router-link>
            </li>
            <li class="nav--item" v-if="profile">
              <router-link class="nav--link" to="/dashboard">Dashboard</router-link>
            </li>
            <li class="nav--item">
              <router-link class="nav--link" to="/documentation/account">Doc</router-link>
            </li>
            <li class="nav--item" v-if="!profile">
              <router-link class="nav--link" to="/login">Login</router-link>
            </li>
            <li class="nav--item" v-if="profile">
              <router-link class="nav--link" to=""><a @click="onLogout">Logout</a></router-link>
            </li>
          </ul>
        </nav> -->
      </div>
      <div class="header--item"></div>
		</header>
	</div>
</template>

<script>
// @ is an alias to /src
import { mapGetters, mapActions } from "vuex";
export default {
  components: {
    // nav,
    // nav
  },
  data() {
    return {};
  },
  computed: {
    ...mapGetters(["profile"]),
  },
  methods: {
    ...mapActions(["logout"]),
    async onLogout() {
      await this.logout();
      // this.$router.push("/login");
      this.$router.push({ name: "Login" });
      // window.location.reload();
    },
  },
};
</script>

<style>
.header {
  margin: 0;
  padding: 0;
}
#nav {
  padding: 10px;
}
.nav .nav--list {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-top: 0.75rem;
  width: 100%;
}
.nav .nav--item {
  list-style-type: none;
  margin-left: 1rem;
  /*margin-right: 1rem;*/
}
.nav--item .nav--link {
  color: #ffffff;
  color: #000;
  font-weight: 600;
  text-decoration: underline;
}
.nav--item.logo {
  flex: auto;
}
.logo--icon {
  width: 2rem;
  height: 2rem;
  fill: currentColor;
}
/* Navigation styles */
.nav {
  background-color: white;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05);
}

.nav-container {
  max-width: 72rem;
  margin: 0 auto;
  padding: 1rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.nav-logo {
  display: flex;
  align-items: center;
  gap: 1rem;
}

.logo-icon {
  width: 2rem;
  height: 2rem;
}

.logo-svg {
  fill: #d1d5db;
}

.logo-text {
  font-size: 1.25rem;
  font-weight: 600;
  color: #1f2937;
}

.nav-links {
  display: flex;
  align-items: center;
  gap: 1.5rem;
}

.nav-link {
  color: #4b5563;
  text-decoration: none;
}

.nav-link:hover {
  color: #1f2937;
}

.sign-in-button {
  background-color: #d1d5db;
  color: #000000;
  padding: 0.5rem 1rem;
  border-radius: 0.5rem;
  border: none;
  cursor: pointer;
  /* box-shadow: 0 1px 2px 0 rgb(60 64 67 / 30%), 0 1px 3px 1px rgb(60 64 67 / 15%); */
}

.sign-in-button:hover {
  background-color: #6d28d9;
  background-color: #f3f4f6;
}
/* mini */
/* @media only screen and (min-width: 481px) {
  .nav .nav--item {
    list-style-type: none;
    margin-left: 1rem;
    margin-right: 1rem;
  }
  .nav .nav--list {
    margin-left: auto;
    margin-right: auto;
    margin-top: 0rem;
    padding-left: 0.75rem;
    padding-right: 0.75rem;
  }
  .logo--icon {
    width: 5rem;
    height: 5rem;
    fill: currentColor;
  }
} */
/* max */
@media only screen and (min-width: 981px) {
  .nav .nav--list {
    width: 70%;
    margin-left: auto;
    margin-right: auto;
    margin-top: 0rem;
  }
  .logo--icon {
    width: 5rem;
    height: 5rem;
    fill: currentColor;
  }
}
@media only screen and (max-width: 640px) {
  .nav-container {
    flex-direction: column;
    gap: 1rem;
  }
}
</style>
