<!-- Home.vue -->
<template>
  <div class="home">
    <!-- Navigation -->
    <Header></Header>

    <!-- Hero Section -->
    <div class="hero-section">
      <div class="hero-grid">
        <div class="hero-content">
          <h1 class="hero-title">Build Dynamic Forms in Minutes</h1>
          <p class="hero-description">
            Create, deploy, and analyze sophisticated forms with our intuitive platform.
            No coding required.
          </p>
          <div class="hero-buttons">
            <button type="button" class="primary-button" @click="onLogin">
              Get Started Free
              <ArrowRightIcon></ArrowRightIcon>
              <span class="icon-arrow-right"></span>
            </button>
            <button type="button" class="primary-button">View Demo</button>
          </div>
        </div>
        <div class="hero-image">
          <!-- <Hero></Hero> -->
          <img alt="Hero logo" src="../assets/landing.svg" width="100%" height="100%">
        </div>
      </div>
    </div>

    <!-- Form Section -->
    <FormSection></FormSection>

    <!-- Insight Section -->
    <InsightsSection></InsightsSection>

    <!-- Designer Section -->
    <DesignerSection></DesignerSection>

    <!-- Preview Section -->
    <PreviewSection></PreviewSection>

    <!-- Theme Section -->
    <ThemeSection></ThemeSection>

    <!-- Features Section -->
    <FeatureSection></FeatureSection>

    <!-- Main -->
    <Main></Main>

    <!-- Footer -->
    <Footer></Footer>
  </div>
</template>

<script>
// import Hero from "@/components/partials/Hero.vue";
import Header from "@/components/partials/Header.vue";
import Footer from "@/components/partials/Footer.vue";
import Main from "@/components/partials/Main.vue";
import FormSection from "@/components/Landing/FormSection.vue";
import InsightsSection from "@/components/Landing/InsightsSection";
import DesignerSection from "@/components/Landing/DesignerSection.vue";
import PreviewSection from "@/components/Landing/PreviewSection.vue";
import ThemeSection from "@/components/Landing/ThemeSection.vue";
import FeatureSection from "@/components/Landing/FeatureSection.vue";
import ArrowRightIcon from "@/components/ui/icons/ArrowRightIcon.vue";
import { mapActions } from 'vuex';
export default {
  components: {
    // Hero,
    Header,
    Footer,
    Main,
    FormSection,
    InsightsSection,
    DesignerSection,
    PreviewSection,
    ThemeSection,
    FeatureSection,
    ArrowRightIcon,
  },
  data() {
    return {
      formTitle: 'Customer Feedback Form',
    }
  },
  methods: {
    ...mapActions(["setMessage",]),
    scrollToTop() {
      window.scrollTo({ top: 0, behavior: 'smooth' });
    },
    async onLogin() {
      try {
        this.$router.push({ name: "Login" });
      } catch (error) {
        this.setMessage({ text: error.message, status: false });
      }
    },
  }
}
</script>

<style>
.home {
  min-height: 100vh;
  background-color: #f9fafb;
}

/* Hero section styles */
.hero-section {
  max-width: 72rem;
  margin: 0 auto;
  padding: 4rem 1rem;
}

.hero-grid {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 3rem;
  align-items: center;
}

@media (max-width: 768px) {
  .hero-grid {
    grid-template-columns: 1fr;
  }
}

.hero-content {
  text-align: left;
}

.hero-title {
  font-size: 3rem;
  font-weight: 700;
  color: #111827;
  margin-bottom: 1.5rem;
}

.hero-description {
  font-size: 1.25rem;
  color: #4b5563;
  margin-bottom: 2rem;
}

.hero-buttons {
  display: flex;
  gap: 1rem;
}

.primary-button {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.5rem;
  background-color: #d1d5db;
  color: #000000;
  padding: 0.75rem 1.5rem;
  border-radius: 0.5rem;
  border: none;
  cursor: pointer;
  box-shadow: 0 1px 2px 0 rgb(60 64 67 / 30%), 0 1px 3px 1px rgb(60 64 67 / 15%);
}

.primary-button:hover {
  background-color: #6d28d9;
  background-color: #f3f4f6;
}

.secondary-button {
  border: 1px solid #d1d5db;
  color: #374151;
  padding: 0.75rem 1.5rem;
  border-radius: 0.5rem;
  background-color: white;
  cursor: pointer;
}

.secondary-button:hover {
  background-color: #f9fafb;
}

.hero-image {
padding: 1rem;
  background-color: white;
  border-radius: 0.75rem;
  box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1);
  border: 1px solid #f3f4f6;
}

.scroll-top-button {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 2.5rem;
  height: 2.5rem;
  background-color: #d1d5db;
  color: white;
  border-radius: 9999px;
  border: none;
  box-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1);
  position: fixed;
  bottom: 2rem;
  right: 2rem;
  cursor: pointer;
}

.scroll-top-button:hover {
  background-color: #6d28d9;
}

.scroll-icon {
  width: 1.5rem;
  height: 1.5rem;
  stroke: currentColor;
  stroke-width: 2;
  stroke-linecap: round;
  stroke-linejoin: round;
  fill: none;
}

/* Responsive adjustments */
@media (max-width: 1024px) {
}

@media (max-width: 640px) {

  .hero-buttons {
    flex-direction: column;
    gap: 1rem;
  }

  .hero-title {
    font-size: 2rem;
  }
}
</style>