<template>
    <section class="features-section">
        <div class="features-container">
            <div class="features-header">
                <h2 class="features-title">Everything You Need</h2>
                <p class="features-description">
                    Our comprehensive platform provides all the tools and support you need to create,
                    manage, and optimize your forms.
                </p>
            </div>
            <div class="features-grid">
                <div v-for="feature in features" :key="feature.title" class="feature-card">
                    <div class="feature-icon">
                        <component :is="feature.icon" class="icon" />
                    </div>
                    <h3 class="feature-title">{{ feature.title }}</h3>
                    <p class="feature-description">{{ feature.description }}</p>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
import CircleCheckIcon from "@/components/ui/icons/CircleCheckIcon.vue";
import FileTextIcon from "@/components/ui/icons/FileTextIcon.vue";
import ShieldIcon from "@/components/ui/icons/ShieldIcon.vue";
import BookIcon from "@/components/ui/icons/BookIcon.vue";
import MessageIcon from "@/components/ui/icons/MessageIcon.vue";
import BranchIcon from "@/components/ui/icons/BranchIcon.vue";
export default {
    name: 'featuresSection',
    components: {
        CircleCheckIcon,
        FileTextIcon,
        ShieldIcon,
        BookIcon,
        MessageIcon,
        BranchIcon,
    },
    data() {
        return {
            formTitle: 'Customer Feedback Form',
            fieldLabel: '',
            fieldPlaceholder: '',
            fieldValidation: 'Required',
            formComponents: [
                'Text Input',
                'Select Menu',
                'Radio Group'
            ],
            features: [
                {
                    icon: FileTextIcon,
                    title: 'Smart Templates',
                    description: 'Start with pre-built templates or create your own. Customize every aspect to match your needs.'
                },
                {
                    icon: CircleCheckIcon,
                    title: 'Real-time Validation',
                    description: 'Ensure data quality with built-in validation rules and custom logic that runs in real-time.'
                },
                {
                    icon: ShieldIcon,
                    title: 'Enterprise Security',
                    description: 'Bank-level encryption and compliance with major security standards to protect your data.'
                },
                {
                    icon: BookIcon,
                    title: 'Documentation',
                    description: 'Access detailed documentation, video tutorials, code examples, and best practices to quickly build and deploy your forms.'
                },
                {
                    icon: MessageIcon,
                    title: 'Live Chat',
                    description: 'Connect with our expert support team 24/7 for immediate assistance with technical issues, form design, or platform features.'
                },
                {
                    icon: BranchIcon,
                    title: 'Real-time Collaboration',
                    description: 'Work together seamlessly with your team. Share, edit, and update forms in real-time.'
                },
            ]
        }
    },
}
</script>

<style>
.features-section {
    background-color: #F9FAFB;
    background-image: url("../../assets/login.svg");
    background-repeat: no-repeat;
    background-position: top;
    background-size: cover;
    padding: 0.5rem;
}

.features-container {
    max-width: 72rem;
    margin: 0 auto;
    padding: 64px 16px;
}

.features-header {
    text-align: center;
    margin-bottom: 48px;
}

.features-title {
    font-size: 30px;
    font-weight: 700;
    color: #111827;
    margin-bottom: 16px;
}

.features-description {
    font-size: 18px;
    color: #4B5563;
    max-width: 768px;
    margin: 0 auto;
}

/* Features grid styles */
.features-grid {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 2rem;
    margin-top: 4rem;
}

@media (max-width: 768px) {
    .features-grid {
        grid-template-columns: 1fr;
    }
}

.feature-card {
    background-color: white;
    padding: 1.5rem;
    border-radius: 0.75rem;
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05);
}

.feature-icon {
    width: 3rem;
    height: 3rem;
    background-color: #f3e8ff;
    background-color: #f3f4f6;
    border-radius: 0.5rem;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 1rem;
}

.icon {
    width: 1.5rem;
    height: 1.5rem;
    color: #d1d5db;
}

.feature-title {
    font-size: 1.25rem;
    font-weight: 600;
    color: #111827;
    margin-bottom: 0.5rem;
}

.feature-description {
    color: #4b5563;
}

@media (max-width: 640px) {
    .features-grid {
        gap: 1rem;
    }
}
</style>