<template>
    <section class="designer-section">
        <div class="designer-container">
            <div class="grid">
                <div class="form-designer">
                    <!-- designer Header -->
                    <div class="designer-header">
                        <div class="header-left">
                            <h3 class="header-title">Form designer</h3>
                            <button class="preview-button">Preview Mode</button>
                        </div>
                        <div class="window-controls">
                            <div class="control red"></div>
                            <div class="control yellow"></div>
                            <div class="control green"></div>
                        </div>
                    </div>

                    <!-- designer Interface -->
                    <div class="designer-grid">
                        <!-- Components Sidebar -->
                        <div class="components-sidebar">
                            <h4 class="sidebar-title">Form Elements</h4>
                            <div class="components-list">
                                <div class="component-item">
                                    <p class="component-text">Text Input</p>
                                </div>
                                <div class="component-item">
                                    <p class="component-text">Select Menu</p>
                                </div>
                                <div class="component-item">
                                    <p class="component-text">Radio Group</p>
                                </div>
                            </div>
                        </div>

                        <!-- designer Canvas -->
                        <div class="designer-canvas">
                            <div class="form-header">
                                <div class="form-title-container">
                                    <input type="text" class="form-title-input" v-model="formTitle" />
                                    <button class="more-options-button">
                                        <svg class="more-icon" viewBox="0 0 24 24">
                                            <path
                                                d="M12 5v.01M12 12v.01M12 19v.01M12 6a1 1 0 110-2 1 1 0 010 2zm0 7a1 1 0 110-2 1 1 0 010 2zm0 7a1 1 0 110-2 1 1 0 010 2z" />
                                        </svg>
                                    </button>
                                </div>
                                <p class="form-subtitle">Tell us about your experience</p>
                            </div>

                            <div class="dropzone">
                                <label class="dropzone-label">Drop form elements here</label>
                                <p class="dropzone-text">Drag and drop components to build your form</p>
                            </div>
                        </div>

                        <!-- Properties Panel -->
                        <div class="properties-panel">
                            <h4 class="panel-title">Properties</h4>
                            <div class="properties-form">
                                <div class="form-group">
                                    <label class="property-label">Field Label</label>
                                    <input type="text" class="property-input" placeholder="Enter label" />
                                </div>
                                <div class="form-group">
                                    <label class="property-label">Placeholder</label>
                                    <input type="text" class="property-input" placeholder="Enter placeholder" />
                                </div>
                                <div class="form-group">
                                    <label class="property-label">Validation</label>
                                    <select class="property-select">
                                        <option>Required</option>
                                        <option>Optional</option>
                                    </select>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="content">
                    <h2 class="heading">Intuitive Form Designer</h2>
                    <p class="description">
                        Drag and drop elements, customize properties, and create sophisticated
                        forms without writing code.
                    </p>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
export default {
    name: 'DesignerSection',
    data() {
        return {
            formTitle: 'Customer Feedback Form'
        }
    }
}
</script>

<style>
.designer-section {
    background-color: white;
}

.designer-container {
    max-width: 72rem;
    margin: 0 auto;
    padding: 64px 16px;
}

.form-designer {
    background-color: white;
    border-radius: 12px;
    box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1);
    border: 1px solid #F3F4F6;
}

.designer-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 16px;
    border-bottom: 1px solid #F3F4F6;
}

.header-left {
    display: flex;
    align-items: center;
    gap: 16px;
}

.header-title {
    font-size: 18px;
    font-weight: 600;
    color: #1F2937;
}

.preview-button {
    font-size: 14px;    
    background-color: #f3f4f6;
    color: #000;
    padding: 4px 12px;
    border-radius: 9999px;
    border: none;
    cursor: pointer;
}

.window-controls {
    display: flex;
    gap: 8px;
}

.control {
    width: 12px;
    height: 12px;
    border-radius: 50%;
}

.control.red {
    background-color: #EF4444;
}

.control.yellow {
    background-color: #F59E0B;
}

.control.green {
    background-color: #10B981;
}

.designer-grid {
    display: grid;
    grid-template-columns: 1fr 2fr 1fr;
    min-height: 384px;
}

.components-sidebar {
    border-right: 1px solid #F3F4F6;
    padding: 16px;
}

.sidebar-title {
    font-size: 14px;
    font-weight: 500;
    color: #374151;
    margin-bottom: 12px;
}

.components-list {
    display: flex;
    flex-direction: column;
    gap: 8px;
}

.component-item {
    background-color: #F9FAFB;
    padding: 12px;
    border-radius: 8px;
    border: 2px dashed #E5E7EB;
    cursor: move;
}

.component-text {
    font-size: 14px;
    color: #4B5563;
}

.designer-canvas {
    background-color: #F9FAFB;
    padding: 24px;
}

.form-header {
    background-color: white;
    padding: 16px;
    border-radius: 8px;
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05);
    border: 1px solid #E5E7EB;
    margin-bottom: 16px;
}

.form-title-container {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    margin-bottom: 8px;
}

.form-title-input {
    font-size: 18px;
    font-weight: 500;
    background-color: transparent;
    border: none;
    padding: 0;
}

.form-title-input:focus {
    outline: none;
}

.more-options-button {
    color: #9CA3AF;
    background: none;
    border: none;
    cursor: pointer;
    padding: 4px;
}

.more-options-button:hover {
    color: #4B5563;
}

.more-icon {
    width: 16px;
    height: 16px;
    fill: none;
    stroke: currentColor;
    stroke-width: 2;
    stroke-linecap: round;
    stroke-linejoin: round;
}

.form-subtitle {
    font-size: 14px;
    color: #4B5563;
}

.dropzone {
    background-color: white;
    padding: 16px;
    border-radius: 8px;
    border: 2px dashed #374151;
    text-align: center;
}

.dropzone-label {
    display: block;
    font-size: 14px;
    font-weight: 500;
    color: #374151;
    margin-bottom: 4px;
}

.dropzone-text {
    font-size: 14px;
    color: #6B7280;
}

.properties-panel {
    border-left: 1px solid #F3F4F6;
    padding: 16px;
}

.panel-title {
    font-size: 14px;
    font-weight: 500;
    color: #374151;
    margin-bottom: 12px;
}

.properties-form {
    display: flex;
    flex-direction: column;
    gap: 16px;
}

.form-group {
    display: flex;
    flex-direction: column;
    gap: 4px;
}

.property-label {
    font-size: 12px;
    color: #6B7280;
}

.property-input,
.property-select {
    width: 100%;
    padding: 8px 12px;
    font-size: 14px;
    border: 1px solid #E5E7EB;
    border-radius: 8px;
}

.property-input:focus,
.property-select:focus {
    outline: none;
    border-color: #9333EA;
}

/* content */
.content {
    text-align: left;
}

.heading {
    font-size: 30px;
    font-weight: 700;
    color: #111827;
    margin-bottom: 16px;
}

.description {
    font-size: 18px;
    color: #4B5563;
    margin-bottom: 24px;
}
</style>